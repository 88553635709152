<template>
  <div class="notification-detail-page">
    <b-card class="mb-3" header="Notification Details">
      <h4 class="card-title blue-title">Title: {{ notification.title }}</h4>
      <p class="card-text"><strong>Message:</strong> {{ notification.message }}</p>
      <!-- <p class="card-text">
        <strong>Vehicle Identification Number:</strong> {{ notification.vehicle?.vin }}
      </p> -->
      <p class="card-text">
        <strong>Vehicle Identification Number:</strong>
        <router-link :to="{ name: 'ListVehicle', params: { vin: notification.vehicle?.vin } }">
          {{ notification.vehicle?.vin }}
        </router-link>
      </p>

      <p class="card-text">
        <strong>Registration Number:</strong> {{ notification.vehicle?.registration_number }}
      </p>
      <p class="card-text">
        <strong>Date and Time:</strong> {{ formattedDate }}
      </p>
      <!-- Trigger the modal with the Delete button -->
      <b-button variant="primary" @click="showDeleteModal" class="mt-3">Delete</b-button>
    </b-card>

    <!-- Delete Confirmation Modal -->
    <b-modal
      id="delete-confirmation-modal"
      ref="deleteModal"
      title="Confirm Delete"
      @ok="deleteNotification"
      ok-title="Confirm"
      ok-variant="danger"
      cancel-title="Cancel"
      hide-footer
    >
      <p>Are you sure you want to delete this notification?</p>
      <b-button variant="primary" @click="confirmDelete" class="mr-2">Confirm</b-button>
      <b-button variant="primary" @click="cancelDelete">Cancel</b-button>
    </b-modal>
  </div>
</template>


<script>
import axios from "../../../axios";
import { BCard, BButton, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BModal,
  },
  data() {
    return {
      notification: {},
      formattedDate: '', // Store the formatted date here
    };
  },
  methods: {
    // Fetch the notification detail using the notification ID from the route params
    async fetchNotificationDetail() {
      const id = this.$route.params.id; // Get the ID from the route parameters
      try {
        const response = await axios.get(`showNotification/${id}`);
        this.notification = response.data.notification; // Adjust based on your API structure
        
        // Format the created_at field
        this.formattedDate = this.formatDate(this.notification.created_at);
      } catch (error) {
   
      }
    },

    // Method to format date and time
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString(); // Adjust format as needed
    },

    // Show the delete confirmation modal
    showDeleteModal() {
      this.$refs.deleteModal.show();
    },

    // Method to delete the notification
    async deleteNotification() {
      const id = this.$route.params.id; // Get the ID from the route parameters
      try {
        await axios.delete(`deleteNotificationAdmin/${id}`); 
        this.$refs.deleteModal.hide(); 
        this.$router.push("/dashboards"); 
      } catch (error) {
      
      }
    },
    confirmDelete() {
      this.deleteNotification(); // Call delete method when confirmed
    },

    // Cancel deletion and close the modal
    cancelDelete() {
      this.$refs.deleteModal.hide(); // Hide the modal when cancelled
    },
  },
  mounted() {
    // Fetch the notification details when the component is mounted
    this.fetchNotificationDetail();
  },
  watch: {
    // Watch for route changes and re-fetch notification when the ID changes
    '$route.params.id'(newId) {
      this.fetchNotificationDetail(); // Re-fetch the notification details based on the new ID
    }
  },
};
</script>


<style scoped>
.blue-title {
  color: blue;
}
</style>
